import React, { useEffect, useState } from "react";
import { Table } from "react-bootstrap";
import { useSelector } from "react-redux";

function groupBy(array, key) {
    return array?.reduce((result, currentValue) => {
        (result[currentValue[key]] = result[currentValue[key]] || []).push(currentValue);
        return result;
    }, {});
}

const Orderdetail = () => {
    const { userList, search, singleWebinarList, orderSearch } = useSelector(state => state.adminReducers);
    const [filteredUserData, setFilteredUserData] = useState([]);
    const [sortConfig, setSortConfig] = useState({ key: null, direction: 'asc' });

    useEffect(() => {
        let filteredData = [];
        if (orderSearch && search) {
            console.log(singleWebinarList)
            if (orderSearch === "Admin Book" && search) {
                filteredData = singleWebinarList.booking.filter(order =>
                    order.user_name && order.user_name.toLowerCase().includes(search.toLowerCase()) && order.payment_type ===null
                );
            }else{
                filteredData = singleWebinarList.booking.filter(order =>
                    order.user_name && order.user_name.toLowerCase().includes(search.toLowerCase()) && order.payment_type === orderSearch
                );
            } 
            const groupedData = groupBy(filteredData, 'order_id');
            const aggregatedUserData = Object.values(groupedData).map(group => ({
                ...group[0],
                serial_number: group.map(order => order.serial_number).join(',')
            }));
            setFilteredUserData(aggregatedUserData);
            setCurrentPage(1);
        }
        else if (orderSearch || search) {
            if (search) {
                filteredData = singleWebinarList.booking.filter(order =>
                    order.user_name && order.user_name.toLowerCase().includes(search.toLowerCase())
                );
            }
            if (orderSearch === "Admin Book") {
                    filteredData = singleWebinarList.booking.filter(order => order.payment_type === orderSearch || order.payment_type === null)
            }
            else if (orderSearch) {
                filteredData = singleWebinarList.booking.filter(order => order.payment_type === orderSearch);
            }
            const groupedData = groupBy(filteredData, 'order_id');
            const aggregatedUserData = Object.values(groupedData).map(group => ({
                ...group[0],
                serial_number: group.map(order => order.serial_number).join(',')
            }));
            setFilteredUserData(aggregatedUserData);
            setCurrentPage(1);
        } else {
            const filteredData = singleWebinarList.booking.filter(user => user.status === "closed");
            const groupedData = groupBy(filteredData, 'order_id');
            const aggregatedUserData = Object.values(groupedData).map(group => ({
                ...group[0],
                serial_number: group.map(order => order.serial_number).join(',')
            }));
            setFilteredUserData(aggregatedUserData);
        }
    }, [orderSearch, userList, singleWebinarList, search]);

    const sortData = (key) => {
        let direction = 'asc';
        if (sortConfig.key === key && sortConfig.direction === 'asc') {
            direction = 'desc';
        }
        setSortConfig({ key, direction });
    };
    console.log(orderSearch, search)
    const sortedData = () => {
        const sortableData = [...filteredUserData];
        if (sortConfig.key) {
            sortableData.sort((a, b) => {
                if (a[sortConfig.key] < b[sortConfig.key]) {
                    return sortConfig.direction === 'asc' ? -1 : 1;
                }
                if (a[sortConfig.key] > b[sortConfig.key]) {
                    return sortConfig.direction === 'asc' ? 1 : -1;
                }
                return 0;
            });
        }
        return sortableData;
    };

    const itemsPerPage = 10;
    const [currentPage, setCurrentPage] = useState(1);

    const startIndex = (currentPage - 1) * itemsPerPage;
    const endIndex = startIndex + itemsPerPage;

    const handlePageChange = (pageNumber) => {
        setCurrentPage(pageNumber);
    };

    return (
        <>
            <div style={{ margin: "2px" ,minHeight:"70vh"}} className="m-2" >
                <Table responsive style={{ borderRadius: "19px", marginTop: "2px" }}>
                    <thead style={{ position: "sticky", top: "0px" }}>
                        <tr className="user-management-table-heading">
                            <th onClick={() => sortData('user_name')}>Customer name<span className="mt-1"><svg xmlns="http://www.w3.org/2000/svg" height={20} width={20} version="1.1" x="0px" y="0px" viewBox="-8 -5.266 32 40" overflow="visible" enable-background="new -8 -5.266 32 32" ><path d="M1,9h14c0.404,0,0.77-0.244,0.924-0.617C15.977,8.26,16,8.129,16,8c0-0.26-0.102-0.516-0.293-0.707l-7-7  c-0.391-0.391-1.023-0.391-1.414,0l-7,7C0.102,7.484,0,7.74,0,8c0,0.129,0.025,0.26,0.076,0.383C0.23,8.756,0.596,9,1,9z" /><path d="M15,12.734H1c-0.404,0-0.77,0.244-0.924,0.617C0.023,13.475,0,13.605,0,13.734c0,0.26,0.102,0.516,0.293,0.707l7,7  c0.391,0.391,1.023,0.391,1.414,0l7-7C15.898,14.25,16,13.994,16,13.734c0-0.129-0.025-0.26-0.076-0.383  C15.77,12.979,15.404,12.734,15,12.734z" /></svg></span></th>
                            <th>Mobile Number</th>
                            <th>No Of Tickets</th>
                            <th>Seat No</th>
                            <th>Payment Method</th>
                        </tr>
                    </thead>
                    <tbody>
                        {sortedData().length > 0 ? (
                            sortedData().slice(startIndex, endIndex).map((order, index) => (
                                <tr key={index} className="user-management-table-detail">
                                    <td style={{ cursor: "default" }}>{order.user_name}</td>
                                    <td style={{ cursor: "default" }}>{order.user_mobile}</td>
                                    <td style={{ cursor: "default" }}>{order.serial_number.split(",").length}</td>
                                    <td style={{ cursor: "default" }}>{order.serial_number}</td>
                                    <td style={{ cursor: "pointer" }}>
                                        <button
                                            style={{
                                                backgroundColor: order.payment_type === "STRIPE" ? "black" : order.payment_type === "VENMO" ? "blue" : order.payment_type?.toUpperCase() === "ZELLE" ? "pink" : order.payment_type === "Admin Book" ? "green" : "green",
                                                color: order.payment_type === "STRIPE" ? "white" : order.payment_type === "VENMO" ? "white" : order.payment_type?.toUpperCase() === "ZELLE" ? "black" : "white",
                                                // paddingLeft: order.payment_type === "Admin Book" ? "40px" : order.payment_type === null ? "40px" : order.payment_type === "ZELLE" ? "57px" : order.payment_type === "CASHAPP" ? "46px" : "54px",
                                                // paddingRight: order.payment_type === "Admin Book" ? "40px" : order.payment_type === null ? "40px" : order.payment_type === "ZELLE" ? "57px" : order.payment_type === "CASHAPP" ? "46px" : "54px",
                                                paddingBottom: "5px",
                                                paddingTop: "5px",
                                                borderRadius: "5px",
                                                width: "100%",
                                                border:"unset",
                                                textAlign: "center",
                                                fontWeight: "bold"
                                            }}
                                        >
                                            {order.payment_type ? order.payment_type.toUpperCase() : "ADMIN BOOK"}
                                        </button>
                                    </td>
                                </tr>
                            ))
                        ) : (
                            <tr>
                                <td colSpan="5">Data not found</td>
                            </tr>
                        )}
                    </tbody>
                </Table>
                {filteredUserData && filteredUserData.length > 10 && (
                    <div className="pagination">
                        <button
                            disabled={currentPage === 1}
                            onClick={() => handlePageChange(currentPage - 1)}
                            className="button-prv mx-2 ps-2 pe-2"
                            style={{ backgroundColor: "#AEDE3E", borderRadius: "5px" }}
                        >
                            Previous
                        </button>
                        {Array.from({ length: Math.ceil(filteredUserData.length / itemsPerPage) }, (_, index) => (
                            <button
                                key={index + 1}
                                onClick={() => handlePageChange(index + 1)}
                                className={currentPage === index + 1 ? 'active ms-2 mx-2 ' : 'ms-2 mx-2'}
                                style={{ borderRadius: "50%", paddingLeft: "11px", paddingRight: "11px", paddingTop: "3px", paddingBottom: "3px", border: "none", backgroundColor: currentPage === index + 1 ? "#AEDE3E" : "" }}
                            >
                                {index + 1}
                            </button>
                        ))}
                        <button
                            disabled={currentPage === Math.ceil(filteredUserData.length / itemsPerPage)}
                            onClick={() => handlePageChange(currentPage + 1)}
                            className="button-prv mx-2 ps-2 pe-2"
                            style={{ backgroundColor: "#AEDE3E", borderRadius: "5px" }}
                        >
                            Next
                        </button>
                    </div>
                )}
            </div>
        </>
    );
};

export default Orderdetail;
