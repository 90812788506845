import React, { useEffect, useContext, useState } from "react";
import { Table, Form } from "react-bootstrap";
import { useNavigate } from "react-router-dom";
import wsSend_request from "../Websocket/webSocketRequest";
import { WebSocketContext } from "../../App";
import { useDispatch, useSelector } from "react-redux";
import moment from "moment";
import 'moment-timezone'
import { getOnsearch } from "../../redux/actions/adminAction";
import { toast } from "react-toastify";

const UserManagement = () => {
  const navigate = useNavigate()
  const { websocket } = useContext(WebSocketContext);
  // const dispatch = useDispatch()
  const { userList, ClearFormSet, search, loginUserDetail } = useSelector(state => state.adminReducers);
  // let userData = userList.list?.filter((user) => user?.user_type === "customer")
  const dispatch = useDispatch()
  const [filteredUserData, setFilteredUserData] = useState([]);
  useEffect(() => {
    if (search && userList) {
      const filteredData = userList?.list?.filter(user =>
        user.full_name.toLowerCase().includes(search.toLowerCase()) || user?.mobile_number.includes(search)
      );
      setFilteredUserData(filteredData);
      setCurrentPage(1)
    } else {
      dispatch(getOnsearch(""))
      setFilteredUserData(userList?.list ? userList?.list.filter(user => user.user_type === "customer") : []);
    }
  }, [search, userList, dispatch]);

  // const userApprove = userData.map((user)=>user.is_approve)
  // const [isChecked, setIsChecked] = useState(""); 

  // const handleSwitchChange = (event) => {
  //   setIsChecked(event.target.checked); 
  // };

  // common date fucntion
  const CommonDateTime = (date, format, method) => {
    if (date !== null) {
      const currentTimezone = moment.tz.guess();
      return moment(date).tz(currentTimezone).format(format);
    } else {
      return method === "show" ? "-" : null;
    };
  }

  const itemsPerPage = 10;
  const [currentPage, setCurrentPage] = useState(1);

  const startIndex = (currentPage - 1) * itemsPerPage;
  const endIndex = startIndex + itemsPerPage;

  const handlePageChange = (pageNumber) => {
    setCurrentPage(pageNumber);
  };


  const handleSwitchChange = (email, isChecked) => {
    if (loginUserDetail?.list?.user_type === "admin") {
      let param = {
        type: "UPDATECUSTOMERAPPROVAL",
        payload: {
          email: email,
          is_approve: isChecked
        }
      }
      wsSend_request(websocket, param);
    };
    if (isChecked) {
      toast.success("User approved successfully");
    } else {
      toast.success("User disapproved successfully");
    }
  };

  useEffect(() => {
    if (loginUserDetail?.list?.user_type === "admin") {
      let param = {
        "type": "GETALLUSERS",
        "payload": {}
      };
      wsSend_request(websocket, param);
    }
  }, [websocket, ClearFormSet]);


  return (
    <>

      <div style={{ margin: "2px" }} className="fixTableHead" >
        <Table responsive style={{ borderRadius: "19px", marginTop: "2px" }}>
          <thead style={{ position: "sticky", top: "0px" }}>
            <tr className="user-management-table-heading">
              <th>Customer name</th>
              <th>Email Address</th>
              <th>Mobile Number</th>
              <th>Join date</th>
              <th>Approval</th>
              <th>Detail</th>
            </tr>
          </thead>
          <tbody>
            {filteredUserData && filteredUserData.length > 0 ? (
              filteredUserData.slice(startIndex, endIndex).map((user) => (
                <tr key={user.id} className="user-management-table-detail">
                  <td style={{ cursor: "default" }}>{user.full_name}</td>
                  <td style={{ cursor: "default" }}>{user.email}</td>
                  <td style={{ cursor: "default" }}>{user.mobile_number}</td>
                  <td style={{ cursor: "default" }}>
                    {" "}
                    {CommonDateTime(user.join_date, "DD MMM YYYY", "show")}
                  </td>
                  <td style={{ cursor: "pointer" }}>
                    <span>
                      <Form.Check
                        type="switch"
                        id="custom-switch"
                        defaultChecked={user.is_approve}
                        onChange={(e) => handleSwitchChange(user.email, e.target.checked)}
                      />
                    </span>
                  </td>
                  <td>
                    <span
                      className="detail-icon"
                      onClick={() => navigate("/edit-user/" + user.id)}
                    >
                      <svg
                        width="12"
                        height="12"
                        viewBox="0 0 12 12"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path
                          d="M8.84625 5.4975C9.07125 5.9475 9.1875 6.43125 9.1875 6.9375C9.1875 8.69625 7.75875 10.125 6 10.125C4.24125 10.125 2.8125 8.69625 2.8125 6.9375C2.8125 5.17875 4.24125 3.75 6 3.75C6.02625 3.75 6.09 3.75 6.1575 3.7725C6.285 3.81375 6.37875 3.9225 6.405 4.05375C6.5625 4.8075 7.23375 5.35875 8.00625 5.35875C8.14875 5.35875 8.2875 5.34 8.4075 5.30625C8.58375 5.2575 8.76375 5.34 8.84625 5.4975ZM6 1.875C3.435 1.875 0.806247 4.125 0.378747 6.68625C0.344997 6.88875 0.483747 7.08375 0.686247 7.1175C0.888747 7.15125 1.08375 7.0125 1.1175 6.81C1.45125 4.83375 3.6075 2.625 6 2.625C8.3925 2.625 10.5487 4.83375 10.8787 6.81C10.9087 6.99375 11.07 7.125 11.25 7.125C11.2687 7.125 11.2912 7.125 11.3137 7.12125C11.5162 7.0875 11.655 6.8925 11.6212 6.69C11.1937 4.125 8.565 1.875 6 1.875Z"
                          fill="#313D15"
                        />
                      </svg>
                    </span>
                  </td>
                </tr>
              ))
            ) : (
              <tr>
                <td colSpan="6">Data not found</td>
              </tr>
            )}
          </tbody>

        </Table>
        {filteredUserData && filteredUserData.length > 0 && <div className="pagination">
          <button
            disabled={currentPage === 1}
            onClick={() => handlePageChange(currentPage - 1)}
            className="button-prv mx-2 ps-2 pe-2"
            style={{ backgroundColor: "#AEDE3E" ,borderRadius:"5px"}}
          >
            Previous
          </button>
          {Array.from({ length: Math.ceil(filteredUserData.length / itemsPerPage) }, (_, index) => (
            <button
              key={index + 1}
              onClick={() => handlePageChange(index + 1)}
              className={currentPage === index + 1 ? 'active ms-2 mx-2 ' : 'ms-2 mx-2'}
              style={{ borderRadius: "50%", paddingLeft: "11px",paddingTop:"3px",paddingBottom:"3px", paddingRight: "11px", border: "none", backgroundColor: currentPage === index + 1 ? "#AEDE3E" : "" }}
            >
              {index + 1}
            </button>
          ))}
          <button
            disabled={currentPage === Math.ceil(filteredUserData.length / itemsPerPage)}
            onClick={() => handlePageChange(currentPage + 1)}
            className="button-prv mx-2 ps-2 pe-2"
            style={{ backgroundColor: "#AEDE3E" ,borderRadius:"5px"}}
          >
            Next
          </button>
        </div>}
      </div>
    </>
  );
};

export default UserManagement;
