import React, { useState, useEffect } from "react";
import BookingDetail from "./bookingDetail";
import CreateWebinar from "./createWebinar";
import { useLocation } from "react-router-dom";
import Orderdetail from "./orderDetail";
import { orderSearch, getOnsearch } from "../../redux/actions/adminAction";
import { useDispatch } from "react-redux";
// import { InputGroup, Form } from 'react-bootstrap'
const WebinarDetail = () => {
    const [activeTab, setActiveTab] = useState("webinarDetail");
    const locationRoute = useLocation()?.pathname?.split("/")[1];
    const [selectedValue, setSelectValue] = useState("")
    const dispatch = useDispatch()
    const [searchQuery, setSearchQuery] = useState('');

    const handleChange = (e) => {
        dispatch(orderSearch(e.target.value))
        setSelectValue(e.target.value)
    }
    useEffect(() => {
        const storedTab = localStorage.getItem("activeTab");
        dispatch(orderSearch(""))
        setSelectValue("")
        if (storedTab && locationRoute === "edit-webinar") {
            setActiveTab(storedTab);
        }
    }, [locationRoute]);

    const handleTabClick = (tabName) => {
        setActiveTab(tabName);
        localStorage.setItem("activeTab", tabName);
    };

    useEffect(() => {
        dispatch(getOnsearch(""))
        setSelectValue("")
    }, [activeTab])

    return (
        <>
            <div className="row">
                <div className="col-12 ">
                    <div className="row p-1 Tab">
                        <div className="col-2">
                        <span
                            onClick={() => handleTabClick("webinarDetail")}
                            className={
                                activeTab === "webinarDetail"
                                    ? "active mx-3 mb-3 pb-3"
                                    : "mx-3 mb-3 pb-2"
                            }
                        >
                            Webinar Detail
                        </span>
                        </div>
                        <div className="col-2">
                        {locationRoute === "edit-webinar" && (
                            <span
                                onClick={() => handleTabClick("bookingDetail")}
                                className={
                                    activeTab === "bookingDetail"
                                        ? "active mx-3 mb-3 pb-3"
                                        : "mx-3 mb-3 pb-2"
                                }
                            >
                                Booking Detail
                            </span>
                        )}
                        </div>
                        <div className="col-2 " style={{marginTop:"1px"}}>
                        {locationRoute === "edit-webinar" && (
                            <span
                                onClick={() => handleTabClick("orderDetail")}
                                className={
                                    activeTab === "orderDetail"
                                        ? "active mx-3"
                                        : "mx-3 mb-3 pb-2"
                                }
                                style={{paddingBottom:"16%"}}
                            >
                                Order Detail
                            </span>
                        )}
                        </div>
                        <div className="col-2 mt-1">
                        {activeTab === "orderDetail"  && <span className="mx-3">
                                    <select value={selectedValue} onChange={handleChange} className="ps-2 pe-3 pb-1">
                                        <option value="">All</option>
                                        <option value="Admin Book">Admin</option>
                                        <option value="STRIPE">Stripe</option>
                                        <option value="VENMO">Venmo </option>
                                        <option value="ZELLE">Zelle</option>
                                    </select>
                                </span>}
                        </div>
                        <div className="col-2 mt-1">
                        {activeTab === "orderDetail"&& <span className="col-2 mx-4">
                                    <input type="search"
                                    style={{paddingLeft:"5px",outlineOffset:"0px"}}
                                     onChange={(e) => {
                                        const newText = e.target.value.replace(/[^a-zA-Z0-9]/g, '');
                                        dispatch(getOnsearch(newText.trim()))
                                        setSearchQuery(newText)
                                    }}
                                    />
                                        </span>}
                        </div>
                        <span className="col-12 border-bottom mt-3" style={{width:"98%",marginLeft:"1%"}}></span>

                    </div>
                   
                    {activeTab === "webinarDetail" && <CreateWebinar />}
                    {activeTab === "bookingDetail" && <BookingDetail />}
                    {activeTab === "orderDetail" && <Orderdetail />}
                </div>
            </div>
        </>
    );
};

export default WebinarDetail;
