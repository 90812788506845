import React, { useContext, useEffect, useState } from "react";
import { Form, Button, InputGroup, Modal } from "react-bootstrap"
import UploadFileAPI from "../CommonModel/uploadFileApi";
import { useDispatch, useSelector } from "react-redux";
import wsSend_request from "../Websocket/webSocketRequest";
import { WebSocketContext } from "../../App";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { Editor } from "react-draft-wysiwyg";
import {
    EditorState,
    convertToRaw,
    convertFromHTML,
    ContentState
} from "draft-js";
import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css";
import draftToHtml from "draftjs-to-html";
import EmptySpaceFieldValid from "../Validation/EmptySpaceValid";
import { ClearFormSetFutios } from "../../redux/actions/adminAction";
import CheckValid from "../Validation/ErrorValidation";
// import { toast } from "react-toastify";
const CreateWebinar = () => {
    const id = parseInt(useParams()?.id);
    const { webinarList, loginUserDetail, singleWebinarList, ClearFormSet } = useSelector(state => state.adminReducers);
    const LocationRoute = useLocation()?.pathname?.split("/")[1];
    const { websocket } = useContext(WebSocketContext);
    const [file, setFile] = useState("");
    const [imageUrl, setImageUrl] = useState(null);
    const [productFiles, setProductFiles] = useState([]);
    const [productImageUrls, setProductImageUrls] = useState([]);
    const navigate = useNavigate()
    const [showModal, setShowModal] = useState(false);
    const [showOption, setShowOption] = useState(false)
    const [offervalue, setOfferValue] = useState("")
    // const handleShowModal = () => setShowModal(true);
    function handleShowModal(e) {
        e.preventDefault()
        setShowModal(true);
        setWebinarState({
            ...webinarState,
            offer: ""
        })
    }
    const handleCloseModal = () => {
        setShowModal(false)
        setShowOption(false)
        setWebinarState({
            ...webinarState,
            offer: ""
        })
        setOffer("")
    };
    const [offer, setOffer] = useState("")
    const [webinarTitleError, setwebinarTitleError] = useState("")
    const [webinarImage, setwebinarimageError] = useState("")
    const [productImageError, setproductImageError] = useState("")
    const [ticketSizeerror, setticketSizeError] = useState("")
    const [ticketPriceError, setticketPriceError] = useState("")
    const [productpriceerror, setproductpriceerror] = useState("")
    // const [descriptionerror,setDescriptionError] = useState("")
    const [webinarState, setWebinarState] = useState({
        webinar_title: "",
        ticket_size: "",
        ticket_price: "",
        description: "",
        product_price: "",
        offer: "",
        userId: "",
        winner_number: "",
        webinar_image: "",
        webinar_url: "",
        productImageUrls: []
    });

    const dispatch = useDispatch()
    const [charCount, setCharCount] = useState("")
    const webinarDetail = webinarList?.list?.filter((webinar) => webinar?.id === id)[0]

    const handleSizeChange = (e) => {
        setWebinarState({ ...webinarState, ticket_size: e.target.value })
        setticketSizeError("")
    };
    const handleCheckboxChange = (e) => {
        const { id } = e.target
        if (id === "Amount") {
            setOffer("Amount")
            setWebinarState({
                ...webinarState,
                offer: ""
            })
            setShowOption(false)
        }
        if (id === "3+1") {
            setWebinarState({
                ...webinarState,
                offer: "3+1"
            })
            setOffer("")
            setShowOption(false)
        }
    }

    const [questionEditorState, setAnsEditorState] = useState(
        EditorState.createEmpty()
    );

    const [faqAnswer, setFaqAnswer] = useState("")
    const maxDescLength = 1024
    const onAnswerEditorChange = (newEditorState) => {
        if (charCount < maxDescLength) {

            setAnsEditorState(newEditorState);
            const contentState = newEditorState.getCurrentContent();
            const plainText = contentState.getPlainText("");
            const characterCount = plainText.length;
            setCharCount(characterCount);
            const contentAsRaw = convertToRaw(contentState);
            const htmlContent = draftToHtml(contentAsRaw);
            const styledHtmlContent = `<div style="font-family: sans-serif;">${htmlContent}</div>`;
            setFaqAnswer(styledHtmlContent);
            setWebinarState((prevInputValue) => ({
                ...prevInputValue,
                description: styledHtmlContent,
            }));
        }
    };

    const UploadImage = async (e) => {
        const file = e.target.files[0];
        setwebinarimageError("")
        if (file) {
            const formData = new FormData();
            formData.append("image", file);

            try {
                const response = await UploadFileAPI(formData)
                // setWebinarState({...webinarState,webinar_image:response.data.filename})
                setFile(response.data.filename);
                setImageUrl(URL.createObjectURL(file));
            } catch (error) {
                console.error("Error uploading file: ", error);
            }
        }
    };

    const RemoveImage = () => {
        setImageUrl("")
        setFile("")
        // setWebinarState({ ...webinarState, imageUrl: "" })
    }

    useEffect(() => {
        let defaultquestionContent = webinarDetail?.description;
        if (LocationRoute === "edit-webinar") {
            const questionContentState = ContentState.createFromBlockArray(
                convertFromHTML(defaultquestionContent)
            );
            const questionEditorState = EditorState.createWithContent(questionContentState);
            setAnsEditorState(questionEditorState);
            setWebinarState(pre => ({
                ...pre,
                description: questionEditorState
            }))
        }
    }, [LocationRoute]);

    useEffect(() => {
        if (LocationRoute === "edit-webinar") {
            setWebinarState(prevState => ({
                ...prevState,
                webinar_title: singleWebinarList?.list?.webinar_title,
                product_price: singleWebinarList?.list?.product_price,
                ticket_size: singleWebinarList?.list?.ticket_size,
                ticket_price: singleWebinarList?.list?.ticket_price,
                description: singleWebinarList?.list?.description,
                webinar_url: singleWebinarList?.list?.webinar_url,
                offer: singleWebinarList?.list?.offer
                // productImageUrls: singleWebinarList?.list?.product_images
            }));

            setFile(singleWebinarList?.list?.webinar_image)
            if (singleWebinarList?.list?.product_images) {
                setProductImageUrls(
                    singleWebinarList.list.product_images.length > 0
                    ? [...singleWebinarList.list.product_images]
                    : []
                );
            }
            setOfferValue(singleWebinarList?.list?.offer)
            setImageUrl(`${process.env.REACT_APP_BASE_URL + 'uploads/' + singleWebinarList?.list?.webinar_image}`)
            const productImages = webinarDetail?.product_images;

            if (productImages && Array.isArray(productImages) && productImages.length > 0) {
                const newFiles = productImages.map(file => `${process.env.REACT_APP_BASE_URL + 'uploads/' + file}`);
                if (productFiles.length + newFiles.length <= 3) {
                    const uniqueFilesSet = new Set([...productFiles, ...newFiles.filter(file => file.trim() !== '')]);
                    const uniqueFilesArray = [...uniqueFilesSet];
                    setProductFiles(uniqueFilesArray);
                } else {
                    console.error("Adding new files exceeds the maximum limit.");
                }
            } else {
                console.error("Product images not found or not iterable.");
            }

        }
    }, [LocationRoute, singleWebinarList, id, webinarDetail?.description, webinarDetail?.webinar_url, webinarDetail?.product_price, webinarDetail?.ticket_price, webinarDetail?.ticket_size, webinarDetail?.webinar_image, webinarDetail?.webinar_title, webinarDetail?.productImageUrls]);

    useEffect(() => {
        if (ClearFormSet?.url === "ADDWEBINAR" && ClearFormSet?.action === 200) {
            navigate("/webinar-management")
            setTimeout(() => {
                dispatch(ClearFormSetFutios({
                    url: "",
                    action: ""
                }))
            }, 1000)
        }
    })

    const handleProductImagesUpload = async (e) => {
        const file = e.target.files[0];
        setproductImageError("")
        if (file && productFiles.length < 3) {
            const formData = new FormData();
            formData.append("image", file);
            try {
                const response = await UploadFileAPI(formData);
                setProductImageUrls(prevUrls => [...prevUrls, response.data.filename]);
                setProductFiles(prevFiles => [...prevFiles, URL.createObjectURL(file)]);
            } catch (error) {
                console.error("Error uploading file: ", error);
            }
        } else {
            console.log("Maximum limit reached or no file selected");
        }
    };
    console.log(file)
    useEffect(() => {
        if (ClearFormSet?.action === 200 && ClearFormSet?.url === "update_webinar") {
            navigate("/webinar-management")
            dispatch(ClearFormSetFutios({
                url: "",
                action: false
            }));
        }
    }, [ClearFormSet,]);
    function AddWebinar(e) {
        e.preventDefault()
        if (file !== "" && file !== null && productImageUrls.length>0 && productImageUrls !== null && webinarState?.webinar_title !== "" && webinarState?.product_price !== "" && webinarState?.ticket_size !== "" && webinarState?.ticket_price !== "" && webinarState?.description !== "") {
            if (LocationRoute === "add-webinar") {
                let param = {
                    "type": "ADDWEBINAR",
                    "payload": {
                        webinar_title: webinarState?.webinar_title,
                        ticket_size: webinarState?.ticket_size,
                        ticket_price: webinarState?.ticket_price,
                        description: webinarState?.description,
                        product_price: webinarState?.product_price,
                        offer: offervalue,
                        webinar_image: file,
                        product_images: [productImageUrls?.length > 0 ? productImageUrls : singleWebinarList?.list?.product_images],
                        userId: loginUserDetail.list.id,
                        webinar_url: webinarState?.webinar_url
                    }
                };
                wsSend_request(websocket, param);
                // toast.success("webinar added successfully")
            } else {
                let param = {
                    "type": "update_webinar",
                    "payload": {
                        webinarId: singleWebinarList?.list?.id,
                        webinar_title: webinarState?.webinar_title,
                        ticket_size: singleWebinarList?.list?.ticket_size,
                        ticket_price: webinarState?.ticket_price,
                        description: webinarState?.description,
                        product_price: webinarState?.product_price,
                        offer: offervalue,
                        webinar_image: file,
                        product_images: productImageUrls,
                        userId: loginUserDetail.list.id
                    }
                };
                wsSend_request(websocket, param);
                // navigate("/webinar-management")
            }
        } else {
            CheckValid(webinarState?.webinar_title, { type: 'webinar_title', error: setwebinarTitleError })
            CheckValid(webinarState?.product_price, { type: 'product_price', error: setproductpriceerror })
            // CheckValid(file, { type: 'webinarimage', error: setwebinarimageError })
            // CheckValid(productImageUrls, { type: 'productimage', error: setproductImageError })
            CheckValid(webinarState?.ticket_price, { type: 'ticket_price', error: setticketPriceError })
            CheckValid(webinarState?.ticket_size, { type: 'ticket_size', error: setticketSizeError })
            // CheckValid(webinarState?.description, { type: 'description', error: setDescriptionError })
            if (file === null || file ==="") {
                setwebinarimageError("webinar image can not be empty")
            }
            if (productImageUrls.length===0) {
                setproductImageError("product image can not be empty")
            }
        }
    }

    const handleModalSubmit = () => {
        setOfferValue(webinarState?.offer)
        handleCloseModal();
    };

    const offerRemove = () => {
        setOfferValue("")
    }

    useEffect(() => {
        let param = {
            "type": "get_single_webinar",
            "payload": {
                webinarId: id,
            }
        };
        wsSend_request(websocket, param);
    }, [LocationRoute, id, websocket])

    const RemoveProductImage = (index) => {
        setProductImageUrls(prevUrls => prevUrls.filter((_, i) => i !== index));
        setProductFiles(prevFiles => prevFiles.filter((_, i) => i !== index));
    };

    return (
        <>

            <div className="container-fluid p-0 ms-3 mt-3">
                <div className="row ">
                    <div className="col-12 col-sm-12 col-lg-12">
                        <div className="row ">
                            <div className="col-12 col-md-12 col-sm-12 col-lg-12">
                                <Form.Group>
                                    <div className="row ">
                                        <div className="col-12 col-md-12 col-sm-12 col-lg-12">
                                            <Form.Label>Webinar image</Form.Label>
                                        </div>
                                        <div className="col-1 col-md-1 col-sm-1 col-lg-1">
                                            <label className="file-upload" htmlFor="fileInput">
                                                <svg width="28" height="28" viewBox="0 0 28 28" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                    <path d="M7.72464 7.875C6.40864 7.875 5.21028 8.65314 4.67581 9.85571L1.82522 16.2695C1.75854 16.3992 1.75 16.5008 1.75 16.625V23.625C1.75 25.0641 2.93588 26.25 4.375 26.25H23.625C25.0641 26.25 26.25 25.0641 26.25 23.625V16.625C26.25 16.5022 26.2174 16.3755 26.1744 16.2695C25.223 14.1325 24.2737 11.9937 23.3238 9.85569C22.7898 8.65315 21.5914 7.875 20.2754 7.875H18.375C17.8918 7.875 17.5 8.26675 17.5 8.75C17.5 9.23325 17.8918 9.625 18.375 9.625H20.2754C20.9043 9.625 21.4692 9.99193 21.7246 10.5667L24.0283 15.75H19.9507C19.0761 15.75 18.2778 16.2667 17.9187 17.0642L17.0608 18.968C16.9826 19.1417 16.8155 19.25 16.625 19.25H11.375C11.1845 19.25 11.0174 19.1417 10.9392 18.968L10.0813 17.0642C9.72229 16.2667 8.92394 15.75 8.04934 15.75H3.97171L6.27542 10.5667C6.53085 9.99193 7.09571 9.625 7.72464 9.625H9.62503C10.1083 9.625 10.5 9.23325 10.5 8.75C10.5 8.26675 10.1083 7.875 9.62503 7.875H7.72464ZM3.50003 17.5H8.04934C8.23982 17.5 8.40694 17.6083 8.48513 17.782L9.34304 19.6858C9.70208 20.4833 10.5004 21 11.375 21H16.625C17.4996 21 18.298 20.4833 18.657 19.6858L19.5149 17.782C19.5931 17.6083 19.7602 17.5 19.9507 17.5H24.5V23.625C24.5 24.1249 24.1249 24.5 23.625 24.5H4.37503C3.87514 24.5 3.50003 24.1249 3.50003 23.625V17.5Z" fill="#313D15" />
                                                    <path d="M14.0256 1.74999C13.9064 1.74657 13.7878 1.76755 13.677 1.81163C13.5662 1.85571 13.4656 1.92197 13.3813 2.00634L10.7563 4.63134C10.5923 4.79543 10.5001 5.01796 10.5001 5.24999C10.5001 5.48203 10.5923 5.70456 10.7563 5.86865C10.9204 6.0327 11.143 6.12486 11.375 6.12486C11.607 6.12486 11.8296 6.0327 11.9936 5.86865L13.125 4.7373V15.75C13.125 15.9821 13.2172 16.2046 13.3813 16.3687C13.5454 16.5328 13.7679 16.625 14 16.625C14.2321 16.625 14.4546 16.5328 14.6187 16.3687C14.7828 16.2046 14.875 15.9821 14.875 15.75V4.7373L16.0063 5.86865C16.1704 6.0327 16.393 6.12486 16.625 6.12486C16.857 6.12486 17.0796 6.0327 17.2436 5.86865C17.4077 5.70456 17.4999 5.48203 17.4999 5.24999C17.4999 5.01796 17.4077 4.79543 17.2436 4.63134L14.6186 2.00634C14.4609 1.84838 14.2488 1.75668 14.0256 1.74999Z" fill="#A1CF35" />
                                                </svg>
                                                <input type="file" id="fileInput" onChange={UploadImage} />
                                            </label>
                                        </div>
                                        <div className="col-4 col-md-4 col-sm-4 col-lg-4 " style={{ marginLeft: "3%" }}>
                                            {imageUrl && <img src={imageUrl} alt="Uploaded" height={65} width={120} className="webinar-image" />}
                                            {imageUrl && <span className="close-icon" onClick={RemoveImage}>x</span>}
                                        </div>
                                    </div>
                                </Form.Group>
                            </div>
                            {webinarImage && <span className="text-danger">{webinarImage}</span>}

                        </div>
                        <div className="row">
                            <div className="col-12 col-md-12 col-sm-12 col-lg-12">
                                <Form.Group>
                                    <div className="row">
                                        <div className="col-12 col-md-12 col-sm-12 col-lg-12">
                                            <Form.Label>Product Images</Form.Label>
                                        </div>
                                        <div className="col-1 col-md-1 col-sm-1 col-lg-1">
                                            <label className="file-upload" htmlFor="fileInput1">
                                                <svg width="28" height="28" viewBox="0 0 28 28" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                    <svg width="28" height="28" viewBox="0 0 28 28" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                        <path d="M7.72464 7.875C6.40864 7.875 5.21028 8.65314 4.67581 9.85571L1.82522 16.2695C1.75854 16.3992 1.75 16.5008 1.75 16.625V23.625C1.75 25.0641 2.93588 26.25 4.375 26.25H23.625C25.0641 26.25 26.25 25.0641 26.25 23.625V16.625C26.25 16.5022 26.2174 16.3755 26.1744 16.2695C25.223 14.1325 24.2737 11.9937 23.3238 9.85569C22.7898 8.65315 21.5914 7.875 20.2754 7.875H18.375C17.8918 7.875 17.5 8.26675 17.5 8.75C17.5 9.23325 17.8918 9.625 18.375 9.625H20.2754C20.9043 9.625 21.4692 9.99193 21.7246 10.5667L24.0283 15.75H19.9507C19.0761 15.75 18.2778 16.2667 17.9187 17.0642L17.0608 18.968C16.9826 19.1417 16.8155 19.25 16.625 19.25H11.375C11.1845 19.25 11.0174 19.1417 10.9392 18.968L10.0813 17.0642C9.72229 16.2667 8.92394 15.75 8.04934 15.75H3.97171L6.27542 10.5667C6.53085 9.99193 7.09571 9.625 7.72464 9.625H9.62503C10.1083 9.625 10.5 9.23325 10.5 8.75C10.5 8.26675 10.1083 7.875 9.62503 7.875H7.72464ZM3.50003 17.5H8.04934C8.23982 17.5 8.40694 17.6083 8.48513 17.782L9.34304 19.6858C9.70208 20.4833 10.5004 21 11.375 21H16.625C17.4996 21 18.298 20.4833 18.657 19.6858L19.5149 17.782C19.5931 17.6083 19.7602 17.5 19.9507 17.5H24.5V23.625C24.5 24.1249 24.1249 24.5 23.625 24.5H4.37503C3.87514 24.5 3.50003 24.1249 3.50003 23.625V17.5Z" fill="#313D15" />
                                                        <path d="M14.0256 1.74999C13.9064 1.74657 13.7878 1.76755 13.677 1.81163C13.5662 1.85571 13.4656 1.92197 13.3813 2.00634L10.7563 4.63134C10.5923 4.79543 10.5001 5.01796 10.5001 5.24999C10.5001 5.48203 10.5923 5.70456 10.7563 5.86865C10.9204 6.0327 11.143 6.12486 11.375 6.12486C11.607 6.12486 11.8296 6.0327 11.9936 5.86865L13.125 4.7373V15.75C13.125 15.9821 13.2172 16.2046 13.3813 16.3687C13.5454 16.5328 13.7679 16.625 14 16.625C14.2321 16.625 14.4546 16.5328 14.6187 16.3687C14.7828 16.2046 14.875 15.9821 14.875 15.75V4.7373L16.0063 5.86865C16.1704 6.0327 16.393 6.12486 16.625 6.12486C16.857 6.12486 17.0796 6.0327 17.2436 5.86865C17.4077 5.70456 17.4999 5.48203 17.4999 5.24999C17.4999 5.01796 17.4077 4.79543 17.2436 4.63134L14.6186 2.00634C14.4609 1.84838 14.2488 1.75668 14.0256 1.74999Z" fill="#A1CF35" />
                                                    </svg>
                                                </svg>
                                                <input id="fileInput1" type="file" onChange={handleProductImagesUpload} />
                                            </label>
                                        </div>
                                        <div className="col-10 col-md-10 col-sm-10 col-lg-10">
                                            <span className="image-container d-inline-flex ms-4">
                                                {productFiles.map((url, index) => (
                                                    <span key={index} className="mx-1">
                                                        <img src={url} alt={`Uploaded ${index + 1}`} height={65} width={120} className="product-image" />
                                                        <span className="remove-icon" onClick={() => RemoveProductImage(index)}>x</span>
                                                    </span>
                                                ))}
                                            </span>
                                        </div>
                                        {productImageError && <span className="text-danger">{productImageError}</span>}
                                    </div>
                                </Form.Group>
                            </div>
                        </div>
                        <div className="row mt-1">
                            <div className="col-4 col-sm-4 col-md-4 col-lg-4">
                                <Form.Group className="mb-2" controlId="formGroupEmail">
                                    <Form.Label>Webinar Title</Form.Label>
                                    <Form.Control
                                        type="text"
                                        placeholder="Enter Webinar Title"
                                        onChange={(e) => setWebinarState({ ...webinarState, webinar_title: e.target.value })}
                                        value={webinarState?.webinar_title}
                                        onKeyDown={EmptySpaceFieldValid}
                                        onKeyUp={(e) => CheckValid(e.target.value, { type: 'webinar_title', error: setwebinarTitleError })}
                                    />
                                </Form.Group>
                                {webinarTitleError && <span className="text-danger">{webinarTitleError}</span>}
                            </div>
                            <div className="col-4 col-sm-4 col-md-4 col-lg-4">
                                <Form.Group className="mb-2" controlId="formGroupEmail">
                                    <Form.Label>Product Cost</Form.Label>
                                    <InputGroup className="mb-2">
                                        <InputGroup.Text id="basic-addon1">
                                            <svg width="8" height="13" viewBox="0 0 8 13" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                <path d="M3.756 11.12C3.156 11.12 2.588 11.048 2.052 10.904C1.516 10.752 1.052 10.536 0.66 10.256L0.984 8.948C1.384 9.22 1.828 9.428 2.316 9.572C2.804 9.716 3.284 9.788 3.756 9.788C4.356 9.788 4.812 9.688 5.124 9.488C5.444 9.288 5.604 9.004 5.604 8.636C5.604 8.42 5.544 8.232 5.424 8.072C5.304 7.912 5.108 7.764 4.836 7.628C4.564 7.484 4.196 7.344 3.732 7.208C2.996 6.96 2.4 6.704 1.944 6.44C1.496 6.176 1.168 5.88 0.96 5.552C0.76 5.224 0.66 4.848 0.66 4.424C0.66 3.672 0.94 3.1 1.5 2.708C2.068 2.316 2.88 2.12 3.936 2.12C4.496 2.12 5.032 2.176 5.544 2.288C6.056 2.4 6.512 2.56 6.912 2.768L6.576 4.076C6.12 3.852 5.692 3.692 5.292 3.596C4.9 3.5 4.472 3.452 4.008 3.452C3.416 3.452 2.968 3.532 2.664 3.692C2.368 3.852 2.22 4.096 2.22 4.424C2.22 4.608 2.276 4.776 2.388 4.928C2.508 5.08 2.696 5.224 2.952 5.36C3.216 5.488 3.564 5.62 3.996 5.756C4.756 6.012 5.36 6.28 5.808 6.56C6.264 6.832 6.592 7.132 6.792 7.46C7 7.78 7.104 8.156 7.104 8.588C7.104 9.388 6.808 10.012 6.216 10.46C5.632 10.9 4.812 11.12 3.756 11.12ZM2.676 12.26V10.424H3.924V12.26H2.676ZM2.712 10.424V6.524H3.9V10.424H2.712ZM3.948 6.524V2.816H5.148V6.524H3.948ZM3.912 2.816V0.98H5.16V2.816H3.912Z" fill="#313D15" />
                                            </svg>
                                        </InputGroup.Text>
                                        <Form.Control
                                            placeholder="Product Cost"
                                            aria-label="Username"
                                            aria-describedby="basic-addon1"
                                            onChange={(e) => setWebinarState({ ...webinarState, product_price: e.target.value.replace(/\D/g, '') })}
                                            onKeyDown={EmptySpaceFieldValid}
                                            value={webinarState?.product_price}
                                            onKeyUp={(e) => CheckValid(e.target.value, { type: 'product_price', error: setproductpriceerror })}
                                        />
                                    </InputGroup>
                                    {productpriceerror && <span className="text-danger">{productpriceerror}</span>}

                                </Form.Group>
                            </div>
                        </div>
                        <div className="row">
                            <div className="col-4 col-sm-4 col-md-4 col-lg-4">
                                <Form.Group className="mb-2" controlId="formGroupEmail">
                                    <Form.Label>Ticket Size</Form.Label>
                                    <div className="custom-select-wrapper">
                                        <Form.Select
                                            aria-label="Default select example"
                                            className="classic"
                                            onChange={handleSizeChange}
                                            disabled={LocationRoute === 'edit-webinar'} // Disable if locationRoute is 'edit-webinar'
                                        >
                                            <option value={webinarState?.ticket_size ? webinarState?.ticket_size : ""}>
                                                {webinarState?.ticket_size ? webinarState?.ticket_size : "Select size"}
                                            </option>
                                            <option value="25">25</option>
                                            <option value="50">50</option>
                                            <option value="75">75</option>
                                            <option value="100">100</option>
                                        </Form.Select>
                                    </div>
                                </Form.Group>
                                {ticketSizeerror && <span className="text-danger">{ticketSizeerror}</span>}
                            </div>
                            <div className="col-4">
                                <Form.Group className="mb-2" controlId="formGroupEmail">
                                    <Form.Label>Ticket Cost</Form.Label>
                                    <InputGroup className="mb-2">
                                        <InputGroup.Text id="basic-addon1">
                                            <svg width="8" height="13" viewBox="0 0 8 13" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                <path d="M3.756 11.12C3.156 11.12 2.588 11.048 2.052 10.904C1.516 10.752 1.052 10.536 0.66 10.256L0.984 8.948C1.384 9.22 1.828 9.428 2.316 9.572C2.804 9.716 3.284 9.788 3.756 9.788C4.356 9.788 4.812 9.688 5.124 9.488C5.444 9.288 5.604 9.004 5.604 8.636C5.604 8.42 5.544 8.232 5.424 8.072C5.304 7.912 5.108 7.764 4.836 7.628C4.564 7.484 4.196 7.344 3.732 7.208C2.996 6.96 2.4 6.704 1.944 6.44C1.496 6.176 1.168 5.88 0.96 5.552C0.76 5.224 0.66 4.848 0.66 4.424C0.66 3.672 0.94 3.1 1.5 2.708C2.068 2.316 2.88 2.12 3.936 2.12C4.496 2.12 5.032 2.176 5.544 2.288C6.056 2.4 6.512 2.56 6.912 2.768L6.576 4.076C6.12 3.852 5.692 3.692 5.292 3.596C4.9 3.5 4.472 3.452 4.008 3.452C3.416 3.452 2.968 3.532 2.664 3.692C2.368 3.852 2.22 4.096 2.22 4.424C2.22 4.608 2.276 4.776 2.388 4.928C2.508 5.08 2.696 5.224 2.952 5.36C3.216 5.488 3.564 5.62 3.996 5.756C4.756 6.012 5.36 6.28 5.808 6.56C6.264 6.832 6.592 7.132 6.792 7.46C7 7.78 7.104 8.156 7.104 8.588C7.104 9.388 6.808 10.012 6.216 10.46C5.632 10.9 4.812 11.12 3.756 11.12ZM2.676 12.26V10.424H3.924V12.26H2.676ZM2.712 10.424V6.524H3.9V10.424H2.712ZM3.948 6.524V2.816H5.148V6.524H3.948ZM3.912 2.816V0.98H5.16V2.816H3.912Z" fill="#313D15" />
                                            </svg>
                                        </InputGroup.Text>
                                        <Form.Control
                                            placeholder="Ticket Cost"
                                            aria-label="Username"
                                            aria-describedby="basic-addon1"
                                            onChange={(e) => setWebinarState({ ...webinarState, ticket_price: e.target.value.replace(/\D/g, '') })}
                                            value={webinarState?.ticket_price}
                                            onKeyDown={EmptySpaceFieldValid}
                                            onKeyUp={(e) => CheckValid(e.target.value, { type: 'ticket_price', error: setticketPriceError })}
                                        />
                                    </InputGroup>
                                    {ticketPriceError && <span className="text-danger">{ticketPriceError}</span>}
                                </Form.Group>
                            </div>
                        </div>
                        <div className="row">
                            <div className="col-8 mb-3">
                                <Form.Label>Description</Form.Label>
                                <Editor
                                    editorState={questionEditorState}
                                    onEditorStateChange={onAnswerEditorChange}
                                    toolbarClassName="toolbarClassName"
                                    wrapperClassName="wrapperClassName"
                                    editorClassName="editorClassName"
                                    wrapperStyle={{
                                        width: "97%",
                                        border: "1px solid #ccc",
                                        backgroundColor: "white",
                                        padding: "10px",
                                        borderRadius: "4px",
                                    }}
                                    editorStyle={{
                                        minHeight: "100px",
                                        border: "1px solid #ccc",
                                        padding: "10px",
                                        borderRadius: "4px",
                                        fontFamily: "sans-serif",
                                    }}
                                    toolbar={{
                                        options: [
                                            "inline",
                                            "blockType",
                                            "fontSize",
                                            "list",
                                            "textAlign",
                                            "colorPicker",
                                            "link",
                                            "emoji",
                                            "history",
                                        ],
                                        inline: {
                                            inDropdown: false,
                                            options: [
                                                "bold",
                                                "italic",
                                                "underline",
                                                "strikethrough",
                                                "superscript",
                                                "subscript",
                                            ],
                                        },
                                        list: {
                                            inDropdown: false,
                                            options: ["unordered", "ordered"],
                                        },
                                        textAlign: {
                                            inDropdown: true,
                                        },
                                        link: { inDropdown: true },
                                        history: { inDropdown: false },
                                    }}
                                />
                            </div>
                            {/* {descriptionerror&&<span className="text-danger">{descriptionerror}</span>} */}

                        </div>
                        <div className="row ">
                            <div className="col-12 d-flex">
                                <div className="col-2">
                                    <Button type="submit" className="button-add-offer float-start rounded" style={{ border: "unset", backgroundColor: "#313D15", color: "white" }} onClick={handleShowModal}>
                                        {offervalue ? "Change Offer" : "Add Offer"}
                                    </Button>
                                </div>
                                <div className="col-4">
                                    {offervalue === "3+1" &&
                                        // <Form.Select aria-label="Default select example" style={{ width: "141px" }} className="classic" onChange={handleSizeChange} defaultValue={singleWebinarList?.list?.offer}>
                                        //     <option value="option1">{offervalue}</option>
                                        // </Form.Select>
                                        <span>
                                            <span>
                                                <Form.Control
                                                    type="text"
                                                    placeholder="Username"
                                                    value={offervalue + "offfer"}
                                                    style={{ width: "130px" }}
                                                    readOnly
                                                />
                                            </span>

                                            <span style={{ justifyContent: "center", padding: "2px", borderRadius: "50%", backgroundColor: "red", color: "white", position: "relative", left: "33%", top: "-50%" }} onClick={offerRemove}>X</span>
                                        </span>
                                    }
                                    {(offervalue !== "" && offervalue !== "3+1") && <Form.Group controlId="formInputGroup" className="mb-3">
                                        <div className="input-group">
                                            {/* <Form.Select aria-label="Default select example" className="classic" onChange={handleSizeChange} defaultValue={singleWebinarList?.list?.offer}>
                                                <option value="option1">{offervalue !== "" ? "Amount" : ""}</option>
                                            </Form.Select> */}
                                            <Form.Control
                                                type="text"
                                                placeholder="Username"
                                                value={"Amount" + "    " + "$" + offervalue}
                                                style={{ width: "130px" }}
                                                readOnly
                                            /></div>
                                        <span style={{ justifyContent: "center", padding: "2px", borderRadius: "50%", backgroundColor: "red", color: "white", position: "relative", left: "94%", top: "-29px" }} onClick={offerRemove}>X</span>
                                    </Form.Group>}
                                </div>
                                <div className="col-2">
                                    <Button type="submit" className="button float-end rounded ps-4 pe-4" style={{ border: "unset", }} onClick={AddWebinar}>
                                        Submit
                                    </Button>
                                </div>
                            </div>
                            {/* <div className="col-2 d-flex">
                                <Button type="submit" className="button-add-offer float-start rounded" style={{ border: "unset", backgroundColor: "#313D15", color: "white" }} onClick={handleShowModal}>
                                    {LocationRoute === "edit-webinar" ? "Change Offer" : "Add Offer"}
                                </Button>
                            </div> */}
                            {/* {singleWebinarList?.list?.offer === "3+1" && <span className="col-2 mx-2">
                    
                                    <Form.Select aria-label="Default select example" className="classic" onChange={handleSizeChange} defaultValue={singleWebinarList?.list?.offer}>
                                        <option value="option1">{singleWebinarList?.list?.offer}</option>
                                    </Form.Select>
                                </span>} */}
                            {/* {(singleWebinarList?.list?.offer !=="" && singleWebinarList?.list?.offer !=="3+1")  && <span className="col-2"><Form.Group controlId="formInputGroup" className="mb-3"> */}

                            {/* <Form.Select aria-label="Default select example" className="classic" onChange={handleSizeChange} defaultValue={singleWebinarList?.list?.offer}>
                                        <option value="option1">{singleWebinarList?.list?.offer}</option>
                                    </Form.Select>
                                        <Form.Control
                                            type="text"
                                            placeholder="Username"
                                        />
                                </Form.Group></span>} */}

                            {/* <div className="col-4">
                                <Button type="submit" className="button float-end rounded" style={{ border: "unset", marginLeft:"12px"}} onClick={AddWebinar}>
                                    Submit
                                </Button>
                            </div> */}
                        </div>
                    </div>
                </div>
            </div>
            {/* <div className="container p-0"> */}
            {/* <Form className="create-webinar-form"> */}
            {/* <div className="row ps-4 mt-3">
                        <div className="col-4">
                            <Form.Label>Webinar image</Form.Label>
                            <div className="file-upload">
                                <svg width="28" height="28" viewBox="0 0 28 28" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <path d="M7.72464 7.875C6.40864 7.875 5.21028 8.65314 4.67581 9.85571L1.82522 16.2695C1.75854 16.3992 1.75 16.5008 1.75 16.625V23.625C1.75 25.0641 2.93588 26.25 4.375 26.25H23.625C25.0641 26.25 26.25 25.0641 26.25 23.625V16.625C26.25 16.5022 26.2174 16.3755 26.1744 16.2695C25.223 14.1325 24.2737 11.9937 23.3238 9.85569C22.7898 8.65315 21.5914 7.875 20.2754 7.875H18.375C17.8918 7.875 17.5 8.26675 17.5 8.75C17.5 9.23325 17.8918 9.625 18.375 9.625H20.2754C20.9043 9.625 21.4692 9.99193 21.7246 10.5667L24.0283 15.75H19.9507C19.0761 15.75 18.2778 16.2667 17.9187 17.0642L17.0608 18.968C16.9826 19.1417 16.8155 19.25 16.625 19.25H11.375C11.1845 19.25 11.0174 19.1417 10.9392 18.968L10.0813 17.0642C9.72229 16.2667 8.92394 15.75 8.04934 15.75H3.97171L6.27542 10.5667C6.53085 9.99193 7.09571 9.625 7.72464 9.625H9.62503C10.1083 9.625 10.5 9.23325 10.5 8.75C10.5 8.26675 10.1083 7.875 9.62503 7.875H7.72464ZM3.50003 17.5H8.04934C8.23982 17.5 8.40694 17.6083 8.48513 17.782L9.34304 19.6858C9.70208 20.4833 10.5004 21 11.375 21H16.625C17.4996 21 18.298 20.4833 18.657 19.6858L19.5149 17.782C19.5931 17.6083 19.7602 17.5 19.9507 17.5H24.5V23.625C24.5 24.1249 24.1249 24.5 23.625 24.5H4.37503C3.87514 24.5 3.50003 24.1249 3.50003 23.625V17.5Z" fill="#313D15" />
                                    <path d="M14.0256 1.74999C13.9064 1.74657 13.7878 1.76755 13.677 1.81163C13.5662 1.85571 13.4656 1.92197 13.3813 2.00634L10.7563 4.63134C10.5923 4.79543 10.5001 5.01796 10.5001 5.24999C10.5001 5.48203 10.5923 5.70456 10.7563 5.86865C10.9204 6.0327 11.143 6.12486 11.375 6.12486C11.607 6.12486 11.8296 6.0327 11.9936 5.86865L13.125 4.7373V15.75C13.125 15.9821 13.2172 16.2046 13.3813 16.3687C13.5454 16.5328 13.7679 16.625 14 16.625C14.2321 16.625 14.4546 16.5328 14.6187 16.3687C14.7828 16.2046 14.875 15.9821 14.875 15.75V4.7373L16.0063 5.86865C16.1704 6.0327 16.393 6.12486 16.625 6.12486C16.857 6.12486 17.0796 6.0327 17.2436 5.86865C17.4077 5.70456 17.4999 5.48203 17.4999 5.24999C17.4999 5.01796 17.4077 4.79543 17.2436 4.63134L14.6186 2.00634C14.4609 1.84838 14.2488 1.75668 14.0256 1.74999Z" fill="#A1CF35" />
                                </svg>
                                <input type="file" onChange={UploadImage} />
                            </div>
                            {imageUrl && <img src={imageUrl} alt="Uploaded" height={62} width={120} className="webinar-image" />}
                            {imageUrl && <span className="close-icon" onClick={RemoveImage}>x</span>}
                         
                        </div>
                    </div> */}
            {/* <div className="row ps-4 mt-2">
                        <div className="col-12" style={{ height: "75px" }}>
                            <label>Product Images</label>
                            <div className="file-upload">
                                <svg width="28" height="28" viewBox="0 0 28 28" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <path d="M7.72464 7.875C6.40864 7.875 5.21028 8.65314 4.67581 9.85571L1.82522 16.2695C1.75854 16.3992 1.75 16.5008 1.75 16.625V23.625C1.75 25.0641 2.93588 26.25 4.375 26.25H23.625C25.0641 26.25 26.25 25.0641 26.25 23.625V16.625C26.25 16.5022 26.2174 16.3755 26.1744 16.2695C25.223 14.1325 24.2737 11.9937 23.3238 9.85569C22.7898 8.65315 21.5914 7.875 20.2754 7.875H18.375C17.8918 7.875 17.5 8.26675 17.5 8.75C17.5 9.23325 17.8918 9.625 18.375 9.625H20.2754C20.9043 9.625 21.4692 9.99193 21.7246 10.5667L24.0283 15.75H19.9507C19.0761 15.75 18.2778 16.2667 17.9187 17.0642L17.0608 18.968C16.9826 19.1417 16.8155 19.25 16.625 19.25H11.375C11.1845 19.25 11.0174 19.1417 10.9392 18.968L10.0813 17.0642C9.72229 16.2667 8.92394 15.75 8.04934 15.75H3.97171L6.27542 10.5667C6.53085 9.99193 7.09571 9.625 7.72464 9.625H9.62503C10.1083 9.625 10.5 9.23325 10.5 8.75C10.5 8.26675 10.1083 7.875 9.62503 7.875H7.72464ZM3.50003 17.5H8.04934C8.23982 17.5 8.40694 17.6083 8.48513 17.782L9.34304 19.6858C9.70208 20.4833 10.5004 21 11.375 21H16.625C17.4996 21 18.298 20.4833 18.657 19.6858L19.5149 17.782C19.5931 17.6083 19.7602 17.5 19.9507 17.5H24.5V23.625C24.5 24.1249 24.1249 24.5 23.625 24.5H4.37503C3.87514 24.5 3.50003 24.1249 3.50003 23.625V17.5Z" fill="#313D15" />
                                    <path d="M14.0256 1.74999C13.9064 1.74657 13.7878 1.76755 13.677 1.81163C13.5662 1.85571 13.4656 1.92197 13.3813 2.00634L10.7563 4.63134C10.5923 4.79543 10.5001 5.01796 10.5001 5.24999C10.5001 5.48203 10.5923 5.70456 10.7563 5.86865C10.9204 6.0327 11.143 6.12486 11.375 6.12486C11.607 6.12486 11.8296 6.0327 11.9936 5.86865L13.125 4.7373V15.75C13.125 15.9821 13.2172 16.2046 13.3813 16.3687C13.5454 16.5328 13.7679 16.625 14 16.625C14.2321 16.625 14.4546 16.5328 14.6187 16.3687C14.7828 16.2046 14.875 15.9821 14.875 15.75V4.7373L16.0063 5.86865C16.1704 6.0327 16.393 6.12486 16.625 6.12486C16.857 6.12486 17.0796 6.0327 17.2436 5.86865C17.4077 5.70456 17.4999 5.48203 17.4999 5.24999C17.4999 5.01796 17.4077 4.79543 17.2436 4.63134L14.6186 2.00634C14.4609 1.84838 14.2488 1.75668 14.0256 1.74999Z" fill="#A1CF35" />
                                </svg>
                                <input type="file" multiple onChange={handleProductImagesUpload} />
                            </div>
                            <span style={{ position: "relative", left: "19%", top: "-81%", display: "flex" }}>
                                {productFiles.map((url, index) => (

                                    <span key={index} className="image-container" >
                                        <img src={url} alt={`Uploaded ${index + 1}`} height={62} width={120} className="product-image mx-1" />
                                        <span className="remove-icon" onClick={() => RemoveProductImage(index)}>x</span>
                                    </span>
                                ))}
                            </span>

                        </div>
                    </div> */}
            {/* <div className="row ps-4 mt-4">
                        <div className="col-4">
                            <Form.Group className="mb-2" controlId="formGroupEmail">
                                <Form.Label>Webinar Title</Form.Label>
                                <Form.Control
                                    type="text"
                                    placeholder="Enter Webinar Title"
                                    onChange={(e) => setWebinarState({ ...webinarState, webinar_title: e.target.value })}
                                    value={webinarState?.webinar_title}
                                />
                            </Form.Group>
                        </div>
                        <div className="col-4">
                            <Form.Group className="mb-2" controlId="formGroupEmail">
                                <Form.Label>Product Cost</Form.Label>
                                <InputGroup className="mb-2">
                                    <InputGroup.Text id="basic-addon1">
                                        <svg width="8" height="13" viewBox="0 0 8 13" fill="none" xmlns="http://www.w3.org/2000/svg">
                                            <path d="M3.756 11.12C3.156 11.12 2.588 11.048 2.052 10.904C1.516 10.752 1.052 10.536 0.66 10.256L0.984 8.948C1.384 9.22 1.828 9.428 2.316 9.572C2.804 9.716 3.284 9.788 3.756 9.788C4.356 9.788 4.812 9.688 5.124 9.488C5.444 9.288 5.604 9.004 5.604 8.636C5.604 8.42 5.544 8.232 5.424 8.072C5.304 7.912 5.108 7.764 4.836 7.628C4.564 7.484 4.196 7.344 3.732 7.208C2.996 6.96 2.4 6.704 1.944 6.44C1.496 6.176 1.168 5.88 0.96 5.552C0.76 5.224 0.66 4.848 0.66 4.424C0.66 3.672 0.94 3.1 1.5 2.708C2.068 2.316 2.88 2.12 3.936 2.12C4.496 2.12 5.032 2.176 5.544 2.288C6.056 2.4 6.512 2.56 6.912 2.768L6.576 4.076C6.12 3.852 5.692 3.692 5.292 3.596C4.9 3.5 4.472 3.452 4.008 3.452C3.416 3.452 2.968 3.532 2.664 3.692C2.368 3.852 2.22 4.096 2.22 4.424C2.22 4.608 2.276 4.776 2.388 4.928C2.508 5.08 2.696 5.224 2.952 5.36C3.216 5.488 3.564 5.62 3.996 5.756C4.756 6.012 5.36 6.28 5.808 6.56C6.264 6.832 6.592 7.132 6.792 7.46C7 7.78 7.104 8.156 7.104 8.588C7.104 9.388 6.808 10.012 6.216 10.46C5.632 10.9 4.812 11.12 3.756 11.12ZM2.676 12.26V10.424H3.924V12.26H2.676ZM2.712 10.424V6.524H3.9V10.424H2.712ZM3.948 6.524V2.816H5.148V6.524H3.948ZM3.912 2.816V0.98H5.16V2.816H3.912Z" fill="#313D15" />
                                        </svg>
                                    </InputGroup.Text>
                                    <Form.Control
                                        placeholder="Product Cost"
                                        aria-label="Username"
                                        aria-describedby="basic-addon1"
                                        onChange={(e) => setWebinarState({ ...webinarState, product_price: e.target.value.replace(/^(\+|-)?0+/, "$1") })}
                                        value={webinarState?.product_price}
                                    />
                                </InputGroup>
                            </Form.Group>
                        </div>
                    </div> */}
            {/* <div className="row ps-4">
                    <div className="col-4">
                        <Form.Group className="mb-2" controlId="formGroupEmail">
                            <Form.Label>Ticket Size</Form.Label>
                            <div className="custom-select-wrapper">
                                <Form.Select aria-label="Default select example" className="classic" onChange={handleSizeChange} >
                                    <option>Select size</option>
                                    <option value="25">25</option>
                                    <option value="50">50</option>
                                    <option value="75">75</option>
                                    <option value="100">100</option>
                                </Form.Select>
                            </div>
                        </Form.Group>
                    </div>
                    <div className="col-4">
                        <Form.Group className="mb-2" controlId="formGroupEmail">
                            <Form.Label>Ticket Cost</Form.Label>
                            <InputGroup className="mb-2">
                                <InputGroup.Text id="basic-addon1">
                                    <svg width="8" height="13" viewBox="0 0 8 13" fill="none" xmlns="http://www.w3.org/2000/svg">
                                        <path d="M3.756 11.12C3.156 11.12 2.588 11.048 2.052 10.904C1.516 10.752 1.052 10.536 0.66 10.256L0.984 8.948C1.384 9.22 1.828 9.428 2.316 9.572C2.804 9.716 3.284 9.788 3.756 9.788C4.356 9.788 4.812 9.688 5.124 9.488C5.444 9.288 5.604 9.004 5.604 8.636C5.604 8.42 5.544 8.232 5.424 8.072C5.304 7.912 5.108 7.764 4.836 7.628C4.564 7.484 4.196 7.344 3.732 7.208C2.996 6.96 2.4 6.704 1.944 6.44C1.496 6.176 1.168 5.88 0.96 5.552C0.76 5.224 0.66 4.848 0.66 4.424C0.66 3.672 0.94 3.1 1.5 2.708C2.068 2.316 2.88 2.12 3.936 2.12C4.496 2.12 5.032 2.176 5.544 2.288C6.056 2.4 6.512 2.56 6.912 2.768L6.576 4.076C6.12 3.852 5.692 3.692 5.292 3.596C4.9 3.5 4.472 3.452 4.008 3.452C3.416 3.452 2.968 3.532 2.664 3.692C2.368 3.852 2.22 4.096 2.22 4.424C2.22 4.608 2.276 4.776 2.388 4.928C2.508 5.08 2.696 5.224 2.952 5.36C3.216 5.488 3.564 5.62 3.996 5.756C4.756 6.012 5.36 6.28 5.808 6.56C6.264 6.832 6.592 7.132 6.792 7.46C7 7.78 7.104 8.156 7.104 8.588C7.104 9.388 6.808 10.012 6.216 10.46C5.632 10.9 4.812 11.12 3.756 11.12ZM2.676 12.26V10.424H3.924V12.26H2.676ZM2.712 10.424V6.524H3.9V10.424H2.712ZM3.948 6.524V2.816H5.148V6.524H3.948ZM3.912 2.816V0.98H5.16V2.816H3.912Z" fill="#313D15" />
                                    </svg>
                                </InputGroup.Text>
                                <Form.Control
                                    placeholder="Ticket Cost"
                                    aria-label="Username"
                                    aria-describedby="basic-addon1"
                                    onChange={(e) => setWebinarState({ ...webinarState, ticket_price: e.target.value.replace(/^(\+|-)?0+/, "$1") })}
                                    value={webinarState?.ticket_price}
                                />
                            </InputGroup>
                        </Form.Group>
                    </div>

                </div> */}
            {/* <div className="row ps-4"> */}
            {/* <div className="col-4">
                            <Form.Group className="mb-3" controlId="exampleForm.ControlTextarea1">
                                <Form.Label>Description</Form.Label>
                                <Form.Control
                                    as="textarea"
                                    rows={3}
                                    placeholder="About 2 Line"
                                    onChange={(e) => setWebinarState({ ...webinarState, description: e.target.value })}
                                    value={webinarState?.description}
                                />
                            </Form.Group>
                        </div> */}
            {/* <div className="col-8 mb-3">
                        <Form.Label>Description</Form.Label>
                        <Editor
                            editorState={questionEditorState}
                            onEditorStateChange={onAnswerEditorChange}
                            toolbarClassName="toolbarClassName"
                            wrapperClassName="wrapperClassName"
                            editorClassName="editorClassName"
                            wrapperStyle={{
                                width: "97%",
                                border: "1px solid #ccc",
                                backgroundColor: "white",
                                padding: "10px",
                                borderRadius: "4px",
                            }}
                            editorStyle={{
                                minHeight: "100px",
                                border: "1px solid #ccc",
                                padding: "10px",
                                borderRadius: "4px",
                                fontFamily: "sans-serif",
                            }}
                            toolbar={{
                                options: [
                                    "inline",
                                    "blockType",
                                    "fontSize",
                                    "list",
                                    "textAlign",
                                    "colorPicker",
                                    "link",
                                    "emoji",
                                    "history",
                                ],
                                inline: {
                                    inDropdown: false,
                                    options: [
                                        "bold",
                                        "italic",
                                        "underline",
                                        "strikethrough",
                                        "superscript",
                                        "subscript",
                                    ],
                                },
                                list: {
                                    inDropdown: false,
                                    options: ["unordered", "ordered"],
                                },
                                textAlign: {
                                    inDropdown: true,
                                },
                                link: { inDropdown: true },
                                history: { inDropdown: false },
                            }}
                        />
                    </div> */}
            {/* <div className="col-4">
                            <Form.Group className="mb-2" controlId="formGroupEmail">
                                <Form.Label>Webinar Url</Form.Label>
                                <Form.Control
                                    type="text"
                                    placeholder="Enter Webinar Url"
                                    onChange={(e) => setWebinarState({ ...webinarState, webinar_url: e.target.value })}
                                    value={webinarState?.webinar_url}
                                />
                            </Form.Group>
                        </div> */}
            {/* </div> */}
            {/* <div className="row ps-4">
                    <div className="col-4 d-flex">
                        <Button type="submit" className="button-add-offer float-start rounded" style={{ border: "unset", backgroundColor: "#313D15", color: "white" }} onClick={handleShowModal}>
                            {LocationRoute === "edit-webinar" ? "Change Offer" : "Add Offer"}
                        </Button>
                        <span className="col-6 mx-2">
                            {singleWebinarList?.list !== "" && <Form.Group controlId="exampleForm.SelectCustom">
                                <Form.Control
                                    placeholder="Offer type"
                                    aria-label="Ticket Cost"
                                    aria-describedby="basic-addon1"
                                    onChange={(e) => setWebinarState({ ...webinarState, offer: e.target.value.replace(/^(\+|-)?0+/, "$1") })}
                                    value={webinarState?.offer}
                                    readOnly
                                />
                                <svg width="32" height="28" viewBox="0 0 32 28" fill="none" xmlns="http://www.w3.org/2000/svg" style={{ position: "absolute", left: "443px", top: "21px" }}>
                                    <rect width="32" height="28" rx="2" fill="#AEDE3E" />
                                    <path d="M20 12L16 16L12 12" stroke="#313D15" stroke-width="1.4" stroke-linecap="round" stroke-linejoin="round" />
                                </svg>
                            </Form.Group>}
                        </span>
                    </div>
                    <div className="col-4">
                        <Button type="submit" className="button float-end rounded" style={{ border: "unset" }} onClick={AddWebinar}>
                            Submit
                        </Button>
                    </div>
                </div> */}
            {/* </Form> */}
            <Modal show={showModal} onHide={handleCloseModal}>
                <Modal.Header closeButton>
                    <Modal.Title>Create Offer</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <Form.Group controlId="exampleForm.SelectCustom">
                        <Form.Control
                            placeholder="Offer type"
                            aria-label="Ticket Cost"
                            aria-describedby="basic-addon1"
                            value={offer === "Amount" ? "Amount" : (webinarState?.offer || "")}
                            onClick={() => setShowOption(true)}
                            onKeyDown={EmptySpaceFieldValid}
                        />
                    </Form.Group>
                    <div className="mt-3">
                        {offer === "Amount" && (
                            <Form.Control
                                type="number"
                                min={0}
                                placeholder="Disscount"
                                aria-label="Ticket Cost"
                                aria-describedby="basic-addon1"
                                onChange={(e) => setWebinarState({ ...webinarState, offer: e.target.value.replace(/^(\+|-)?0+/, "$1") })}
                                onKeyDown={(e) => {
                                    if (e.key === "+" || e.key === "-") {
                                        e.preventDefault();
                                    }
                                }}
                            />

                        )}
                        <svg width="32" height="28" viewBox="0 0 32 28" fill="none" xmlns="http://www.w3.org/2000/svg" style={{ position: "absolute", left: "443px", top: "21px" }} onClick={() => setShowOption(true)}>
                            <rect width="32" height="28" rx="2" fill="#AEDE3E" />
                            <path d="M20 12L16 16L12 12" stroke="#313D15" stroke-width="1.4" stroke-linecap="round" stroke-linejoin="round" />
                        </svg>
                    </div>
                    {showOption && (
                        <span className="offer-input">
                            <Form.Check
                                inline
                                type="radio"
                                label="Amount"
                                name="agree"
                                value="Amount"
                                id="Amount"
                                onChange={(e) => handleCheckboxChange(e)}
                            />
                            <br />
                            <Form.Check
                                inline
                                type="radio"
                                label="3+1"
                                name="agree"
                                value="3+1"
                                id="3+1"
                                onChange={(e) => handleCheckboxChange(e)}
                            />
                        </span>
                    )}
                </Modal.Body>
                <Modal.Footer>
                    <button className="ps-4 pe-4 pt-2 pb-2 mx-2" style={{ backgroundColor: "#E6E8DA", border: "unset", borderRadius: "7px" }} onClick={handleCloseModal}>
                        Cancel
                    </button>

                    <button className="ps-4 pe-4 pt-2 pb-2" disabled={!webinarState?.offer} style={{ backgroundColor: "#AEDE3E", border: "unset", borderRadius: "7px" }} onClick={handleModalSubmit}>Submit</button>

                </Modal.Footer>
            </Modal>

            {/* </div > */}

        </>
    );
};

export default CreateWebinar;
